import CmikeSVGLogo from "./img/bric-w.svg";

function App() {
  return (
    <div className="app-cont">
      <h1>Coming Soon</h1>
      <img src={CmikeSVGLogo} alt="Cmike Creative Logo" className="cmike-logo" />
      {/* <p>Portfolio</p> */}
    </div>
  );
}

export default App;
